export default {
    auditData: [
        {
            'id': 1,
            'name': 'A',
        },
        {
            'id': 2,
            'name': 'B',
        },
        {
            'id': 3,
            'name': 'C',
        },
        {
            'id': 4,
            'name': 'CAP-C',
        },
        {
            'id': 5,
            'name': 'D',
        },
        {
            'id': 6,
            'name': 'CAP-D',
        },
        {
            'id': 7,
            'name': 'N/A',
        },
        {
            'id': 8,
            'name': 'Ön Denetim',
        },
        {
            'id': 9,
            'name': 'Yok',
        }
    ],
    yesNo: [
        {
            'id': 1,
            'name': 'Var',
        },
        {
            'id': 2,
            'name': 'Yok',
        }
    ],
    disneyLicences: [
        {
            'id': 1,
            'name': 'A',
        },
        {
            'id': 2,
            'name': 'B',
        },
        {
            'id': 3,
            'name': 'Yok',
        }
    ],
    certificates: [
        {
            'id': 0,
            'name': 'Yok',
        },
        {
            'id': 1,
            'name': 'OCS',
        },
        {
            'id': 2,
            'name': 'RCS',
        },
        {
            'id': 3,
            'name': 'GOTS',
        },
        {
            'id': 4,
            'name': 'GRS',
        }
    ],
    license: [
        {
            'id': 1,
            'name': 'A',
        },
        {
            'id': 2,
            'name': 'B',
        }
    ],
    joinLife: [
        {
            'id': 1,
            'name': 'YOK',
        },
        {
            'id': 2,
            'name': 'CFW',
        },
        {
            'id': 3,
            'name': 'CFP',
        },
        {
            'id': 4,
            'name': 'ORGANIC',
        },
        {
            'id': 5,
            'name': 'RECYCLED',
        },
        {
            'id': 6,
            'name': 'BCI',
        },
        {
            'id': 7,
            'name': 'RWS WOOL',
        },
        {
            'id': 8,
            'name': 'REPREVE',
        },
        {
            'id': 9,
            'name': 'VISKON ECOVERO/ LIVAECO / ECOTANG',
        },
        {
            'id': 9,
            'name': 'MODAL TENCEL / SANYOU',
        },
        {
            'id': 10,
            'name': 'LYOCELL TENCEL / REFIBRA / EXCEL',
        },
        {
            'id': 11,
            'name': 'EUROPEAN FLAX',
        },
        {
            'id': 12,
            'name': 'VISCOSE – ECOTANG/ LIVAECO/ ECOTANG',
        },
        {
            'id': 13,
            'name': 'IN CONVERSION COTTON'
        }
    ],
};
